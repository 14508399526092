import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import BG from "./../images/bg.jpg"

const keywords =
  "Archi Pornic, archi-pornic, archi d'intérieur, architecte d'intérieur, Caroline Corbet, Corbet, CORBET, Pornic, Pays de Retz, Nantes, Saint Brévin les Pins, La Bernerie, décoration, construction, rénovation, bâtiment écologique, matériaux écologiques, bio sourcé, peinture écologique, écologie, responsable, villa, maison"
const meta = [{ name: "keywords", content: keywords }]
const IndexPage = ({ data: { home, contact } }) => (
  <Layout>
    <SEO title="Accueil" meta={meta} />
    <div className="relative flex items-center justify-center md:pl-12 md:py-4 lg:py-10 md:justify-end xl:h-screen xl:pl-10 top-container">
      <div className="absolute z-10 p-4 text-center bg-primary md:relative md:text-left md:-mr-40 md:p-8 lg:-mr-20">
        <h1 className="relative mb-4 text-4xl leading-none tracking-wide md:text-5xl xl:text-6xl font-title">
          Archi <br />
          Pornic
        </h1>
        <p>Architecte d'intérieur / A.M.O</p>
        <p className="font-light">Feng Shui - Vastu Shastra</p>
      </div>
      <div
        className="relative w-full h-full mb-12 bg-center bg-cover shadow-md md:rounded-sm md:mb-0 md:h-full lg:w-8/12 md:shadow-lg"
        style={{
          backgroundImage: `url(${BG})`,
        }}
      >
        <div className="absolute inset-0 w-full h-full bg-white opacity-25"></div>
      </div>
    </div>
    <div className="max-w-3xl px-6 mx-auto md:px-0 md:pt-12 xl:pt-20 md:flex">
      <div
        className="h-1 mx-auto my-5 bg-primary "
        style={{ width: "200px" }}
      ></div>
      <div className="mt-12 md:mt-0 md:ml-12">
        <h2 className="font-serif text-3xl">À propos</h2>

        <div className="relative mt-6 text-lg text-left whitespace-pre-line" dangerouslySetInnerHTML={{ __html: home.about }}></div>
      </div>
    </div>
    <div className="mt-12 mb-16 md:mb-24">
      <p className="text-lg text-center">{contact.name}</p>
      <p className="mt-2 text-center">
        <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>
        <br />
        <a
          href={`mailto:${contact.email}`}
          className="border-b-2 border-primary"
        >
          {contact.email}
        </a>
      </p>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query HomeQuery {
    home: datoCmsHomePage {
      about
    }
    contact: datoCmsContactInfo {
      name
      email
      phoneNumber
    }
  }
`